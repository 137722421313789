import axios from "axios";
import React, { useState, useEffect } from "react";
import r from "../../Assets/R.jpeg";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../utils/constant_api";
import { FaCheckCircle, FaClock } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  Typography,
} from "@mui/material";

const steps = ["Cordonées des passagers", "Resumé de la reservation"];

const ResultatsVoyage = () => {
  const { id } = useParams();

  const [currentStep, setCurrentStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paymentNumber, setPaymentNumber] = useState("");
  const [trip, setTrip] = useState([]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState("");
  const [echec, setEchec] = useState(false);
  const [errorTitle, setErrorTitle] = useState("Echec de la transaction");
  const [gender, setGender] = useState("");
  const [docType, setDocType] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [passagers, setPassengers] = useState([]);

  useEffect(() => {
    setPassengers([
      { nom: "", prenom: "", num_cni_id: "", type_doc: "", telephone: "" },
    ]);
  }, []);

  const handleAddPassenger = () => {
    setPassengers([
      ...passagers,
      { nom: "", prenom: "", num_cni_id: "", type_doc: "", telephone: "" },
    ]);
  };

  const handleRemovePassenger = (index) => {
    const updatedPassengers = [...passagers];
    updatedPassengers.splice(index, 1);
    setPassengers(updatedPassengers);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedPassengers = [...passagers];
    updatedPassengers[index][name] = value;
    setPassengers(updatedPassengers);

    // Check if all passagers have both name and age filled
    const allValid = updatedPassengers.every(
      (passenger) => passenger.nom !== "" && passenger.prenom !== ""
    );
    setFormValid(allValid);
  };

  const handleNext = () => {
    if (formValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setCurrentStep(currentStep + 1);
    } else {
      setIsOpen(true);
      setErrorTitle("Echec");
      setMessage("Veuillez remplir tout les champs");
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCurrentStep(currentStep - 1);
  };

  const [open, setOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    window.location.href = "/travel";
  };

  const Close = () => {
    setIsOpen(false);
  };

  const formattedDate = formatDate(trip.date_de_depart);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("fr-FR", options);
  }

  const onSubmit = async () => {
    const validateForm = () => {
      return paymentNumber.length === 9;
    };

    if (validateForm()) {
      setOpen(true);
      setIsLoading(true);

      const token = localStorage.getItem("token");
      const num_cni_id = passagers[0].num_cni_id;
      const telephone = passagers[0].telephone;
      const voyage_id = id;
      const nombre_place = passagers.length;
      const nom_sur_ticket = passagers[0].nom + " " + passagers[0].prenom;

      await axios
        .post(
          `${baseUrl}/ticket/new`,
          {
            num_cni_id,
            telephone,
            passagers,
            voyage_id,
            nombre_place,
            nom_sur_ticket,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          const ticketId = res.data.ticket.id;
          const paymentToken = localStorage.getItem("paymentToken");

          const ticketData = new FormData();
          ticketData.append("token", paymentToken);
          ticketData.append("ticket", ticketId);
          ticketData.append("phone", paymentNumber);
          axios
            .post(`${baseUrl}/process/paiement/ticket`, ticketData)
            .then((response) => {
              if (response.status === 200) {
                const code = response.data.ussd_code;
                const reference = response.data.reference;

                const verifData = new FormData();
                verifData.append("token", paymentToken);
                verifData.append("ticket", ticketId);
                verifData.append("reference", reference);

                axios
                  .post(`${baseUrl}/paiement/ticket`, verifData)
                  .then((res) => {
                    console.log(res);
                    if (res.data.status === "pending") {
                      setMessage(`Traitement en cours  Veuillez composez le ${code} pour confirmer
                      la transaction.`);
                      let intervalId = setInterval(async () => {
                        const res = await axios.post(
                          `${baseUrl}/paiement/ticket`,
                          verifData
                        );
                        if (res.data.status !== "pending") {
                          clearInterval(intervalId);
                          // Traitez la réponse "res" ici (succès ou échec)
                          if (res.data.status === "success") {
                            setIsLoading(false);
                            setMessage(
                              res.data.message +
                                `votre numéro de ticket de reseration c'est le ${res.data.ticket.numero_reservation} `
                            );
                          } else {
                            setIsLoading(false);
                            setEchec(true);
                            setMessage(
                              `Echec de l'operation veuillez réessayer plus tard !`
                            );
                            console.log("Echec de l'opération");
                          }
                        }
                      }, 15000);
                    } else if (res.data.status === "success") {
                      console.log(res);
                      setLoading(false);
                      setIsComplete(true);
                      console.log("paiement reussit");
                    } else {
                      setLoading(false);
                      console.log("Echec de l'opération");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    setOpen(false);
                    setIsOpen(true);
                    setMessage(error.response.data.message);
                  });
              }
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setIsOpen(true);
      setMessage("veuillez entrer un numero de téléphone valide");
    }
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <div className="md:flex md:gap-2">
              <div className="border rounded-xl shadow-xl w-full md:w-2/3 py-4 mt-4 lg:mt-0 flex-1 lg:mx-8">
                <div>
                  <h1 className="text-xl font-bold text-blue-700">
                    Coordonées des passagers
                  </h1>
                  <h2 className="text-sm text-gray-500 font-medium">
                    Veuillez entrer les coordonées des passagers
                  </h2>
                </div>
                <hr className="my-4" />
                <div>
                  {passagers.map((passenger, index) => (
                    <div key={index} className="my-3 p-4">
                      <div className="mb-2">
                        <h1 className="text-left py-2 font-semibold text-blue-500">
                          Informations du passager {index + 1}
                        </h1>
                        <ul className="flex gap-3">
                          <li className="flex items-center gap-x-2.5">
                            <input
                              onChange={(e) => setGender(e.target.value)}
                              type="radio"
                              name={`gender-${index}`} // Unique name for each passenger
                              value="homme"
                              defaultChecked={passenger.gender === "homme"}
                              id={`homme-${index}`} // Unique ID for each radio group
                              className="form-radio border-gray-400 text-indigo-600 focus:ring-indigo-600 duration-150"
                            />
                            <label
                              htmlFor={`homme-${index}`}
                              className="text-gray-700 font-medium"
                            >
                              Homme
                            </label>
                          </li>
                          <li className="flex items-center gap-x-2.5">
                            <input
                              onChange={(e) => setGender(e.target.value)}
                              type="radio"
                              name={`gender-${index}`}
                              value="femme"
                              defaultChecked={passenger.gender === "femme"}
                              id={`femme-${index}`}
                              className="form-radio border-gray-400 text-indigo-600 focus:ring-indigo-600 duration-150"
                            />
                            <label
                              htmlFor={`femme-${index}`}
                              className="text-gray-700 font-medium"
                            >
                              Femme
                            </label>
                          </li>
                          <li className="flex items-center gap-x-2.5">
                            <input
                              value="enfant"
                              onChange={(e) => setGender(e.target.value)}
                              type="radio"
                              name={`gender-${index}`}
                              id={`enfant-${index}`} // Unique ID for enfant radio
                              className="form-radio border-gray-400 text-indigo-600 focus:ring-indigo-600 duration-150"
                            />
                            <label
                              htmlFor={`enfant-${index}`}
                              className="text-gray-700 font-medium"
                            >
                              Enfant
                            </label>
                          </li>
                        </ul>
                      </div>
                      {gender !== "enfant" && (
                        <>
                          <h1 className="text-left py-2 font-semibold text-gray-400">
                            Pièce d'identité
                          </h1>
                          <div className="flex gap-1 items-center">
                            <div className="md:w-1/4 full">
                              <select
                                name="HeadlineAct"
                                id="HeadlineAct"
                                className="mt-1.5 w-full rounded-lg border-2 text-gray-700 sm:text-sm p-2"
                                onChange={(e) => setDocType(e.target.value)}
                                value={docType}
                              >
                                <option value="CNI">CNI</option>
                                <option value="PASSPORT">PASSPORT</option>
                                <option value="PERMIT DE CONDUIRE">
                                  PERMIT DE CONDUIRE
                                </option>
                                <option value="CARTE SCOLAIRE">
                                  CARTE SCOLAIRE
                                </option>
                              </select>
                            </div>
                            <div className="flex items-center w-full">
                              <input
                                className="py-2 px-4 border border-blue-600 w-full rounded-xl"
                                type="number"
                                placeholder="Numéro de la pièce d'identité"
                                name="num_cni_id"
                                value={passenger.num_cni_id}
                                onChange={(e) => handleChange(e, index)}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="gap-2 lg:flex space-y-2">
                        <div className="flex items-center w-full mt-2">
                          <input
                            type="text"
                            placeholder="Name"
                            className="py-2 px-4 border w-full border-blue-600  rounded-xl"
                            name="nom"
                            value={passenger.nom}
                            onChange={(e) => handleChange(e, index)}
                          />
                        </div>
                        <div className="flex items-center w-full mt-2">
                          <input
                            type="text"
                            name="prenom"
                            placeholder="prenom"
                            value={passenger.prenom}
                            className="py-2 px-4 border w-full border-blue-600  rounded-xl"
                            onChange={(e) => handleChange(e, index)}
                          />
                        </div>
                      </div>
                      <div className="flex items-center w-full mt-2">
                        <input
                          name="telephone"
                          value={passenger.telephone}
                          className="py-2 px-4 border w-full border-blue-600  rounded-xl"
                          onChange={(e) => handleChange(e, index)}
                          type="number"
                          placeholder="Numéro de téléphone"
                        />
                      </div>
                      {index !== 0 && (
                        <div className="flex justify-end">
                          <button
                            onClick={() => handleRemovePassenger(passenger.id)}
                            className="bg-red-500 text-white px-8 mt-2 py-2 rounded-md"
                          >
                            Suprimer
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                  <button
                    onClick={handleAddPassenger}
                    className="border-2 border-blue-600 px-8 py-2 text-blue-600 mt-4 rounded-xl"
                  >
                    Ajouter un passager
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <div className="md:flex md:gap-2">
              <div className="md:flex-1 w-full">
                <div>
                  <h1 className="text-xl font-bold text-blue-700">
                    Resumé de la reservation
                  </h1>
                </div>
                <div className="shadow-xl border rounded-xl p-4 md:p-4 mt-4">
                  <div className="flex items-center gap-2">
                    <img
                      src={trip.logo_agence}
                      alt="logo_agence"
                      className="h-16 w-16 rounded-full border-2 object-cover"
                    />
                    <div className="text-left ml-2">
                      <h1 className="text-xl font-bold text-blue-700 uppercase">
                        {trip.agence}
                      </h1>
                      <div className="gap-2 space-y-2 mt-2">
                        <h2 className="text-sm font-medium text-blue-600">
                          Départ : {trip.ville_depart}{" "}
                          <span className="text-red-600">
                            {trip.lieu_depart}
                          </span>
                        </h2>
                        <h2 className="text-sm font-medium text-blue-600">
                          Arrêt : {trip.ville_arrive}{" "}
                          <span className="text-red-600">{trip.arret}</span>
                        </h2>
                        <h3 className="bg-blue-600 text-white w-[100px] text-center p-2 rounded-lg mt-2">{trip.type_voyage}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="flex justify-start">
                      <p className="font-bold ">{formattedDate}</p>
                    </div>

                    <div>
                      <div className="flex justify-start items-center my-2">
                        <p className="font-medium text-lg bg-blue-600 text-white px-4 py-1 rounded-lg">
                          {trip?.heure_depart?.split("", 5)}
                        </p>
                        {/* <FaBus className="mx-2" color="blue" /> */}
                        {/* <p className="font-medium ">{trip.ville_depart}</p> */}
                      </div>
                    </div>

                    <div className="flex items-center justify-between mt-2">
                      <p className="font-semibold text-lg text-gray-400">
                        {passagers.length} X {trip.net_a_payer} FCFA{" "}
                      </p>
                      <p className="font-bold bg-red-700 text-white rounded px-4 py-2 text-xl">
                        {passagers.length * trip.net_a_payer} FCFA
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border rounded-xl shadow-xl w-full md:w-1/2 p-2 mt-4">
                <div className="flex justify-between my-2 items-center">
                  <label
                    htmlFor=""
                    className="font-medium text-sm text-gray-500"
                  >
                    A propos des passagers :
                  </label>
                </div>

                <div className="border rounded-md py-2">
                  <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                    <thead className="ltr:text-left rtl:text-right">
                      <tr>
                        <th className="whitespace-nowrap py-2 font-medium text-gray-900">
                        Passagers :{" "}
                        </th>
                        <th className="whitespace-nowrap py-2 font-medium text-gray-900">
                        Document ID :{" "}
                        </th>
                        <th className="whitespace-nowrap py-2 font-medium text-gray-900">
                        Télephone :{" "}
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 text-xs">
                      {passagers.map((passager, index) => (
                          <tr key={index}>
                            <td className="  py-2 text-gray-700">
                              {passager.nom} {" "}
                            </td>
                            <td className="whitespace-nowrap py-2 text-gray-700">
                            {passager.num_cni_id}
                            </td>
                            <td className="whitespace-nowrap py-2 text-gray-700">
                            {passager.telephone}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                <div className="flex justify-between items-center my-2">
                  <div>
                    <label className="font-medium text-sm text-gray-500">
                      Coordonées de paiement
                    </label>
                  </div>

                  <div className="flex p-2 items-center gap-2">
                    <img src={r} alt="" className="w-24" />
                  </div>
                </div>

                <div className=" items-center w-full mt-2">
                  <form action="submit" onSubmit={onSubmit}>
                    <div className="flex justify-start">
                      <label className="text-sm font-medium cursor-pointer">
                        Numéro de téléphone du payeur{" "}
                      </label>
                    </div>
                    <input
                      onChange={(e) => setPaymentNumber(e.target.value)}
                      className="py-2 px-4 border-1 border-blue-700 w-full rounded-xl mt-2"
                      type="number"
                      value={paymentNumber}
                      placeholder="Numéro de téléphone du payeur "
                      required
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return "Unknown step";
    }
  };

  useEffect(() => {
    getPaymentToken();
    const getTrip = () => {
      axios.get(`${baseUrl}/voyage/${id}`).then((res) => {
        setTrip(res.data);
      });
    };
    getTrip();
  }, [id]);

  const getPaymentToken = async () => {
    await axios.get(`${baseUrl}/paiement/token`).then((res) => {
      localStorage.setItem("paymentToken", res.data.token);
    });
  };

  return (
    <div className="pt-4 pb-16">
      <div className="py-6  lg:px-36 mb-4 inline-block bg-blue-700 h-content w-full">
        <div className="w-full md:w-2/3 lg:w-1/3 text-start mt-10">
          <h1 className="text-white text-xl font-medium">
            Réservation de voyages
          </h1>
          <h2 className="font text-white mt-2">Service client : 698 42 71 76</h2>
        </div>
      </div>

      <div className="mx-auto mb-4">
        <div className="">
          <Container>
            <div className="boder-1 rounded-lg shadow-lg p-2 mx-auto">
              {activeStep === steps.length ? (
                <div>
                  <Typography>Toutes les étapes sont complétées</Typography>
                </div>
              ) : (
                <div>
                  {getStepContent(activeStep)}
                  <div>
                    <div>
                      <Dialog open={open}>
                        <div
                          role="alert"
                          className="rounded-xl border border-gray-100 bg-white p-4"
                        >
                          {isComplete ? (
                            <div>
                              <div className="flex items-center justify-center">
                                <span className="text-green-600">
                                  {echec ? (
                                    <RiCloseCircleFill size={55} color="red" />
                                  ) : (
                                    <FaCheckCircle size={55} color="green" />
                                  )}
                                </span>
                              </div>

                              <p className="mt-1 text-sm text-gray-700">
                                {message}
                              </p>
                            </div>
                          ) : (
                            <div className="flex items-start gap-4">
                              <span className="text-green-600">
                                <FaClock size={35} color="green" />
                              </span>

                              <div className="flex-1">
                                <strong className="block font-bold">
                                  {" "}
                                  Paiement Initié{" "}
                                </strong>

                                <p className="mt-1 text-sm text-gray-700">
                                  {message}
                                </p>
                              </div>
                            </div>
                          )}

                          <div className="flex justify-center mt-4">
                            {isLoading ? (
                              <div className="flex mx-4 items-center">
                                <CircularProgress />
                              </div>
                            ) : (
                              <button
                                className={
                                  echec
                                    ? "px-10 py-2 border bg-red-600 text-white rounded-xl"
                                    : "px-10 py-2 border bg-green-600 text-white rounded-xl"
                                }
                                onClick={handleClose}
                              >
                                {echec ? "Fermer" : "Terminer"}
                              </button>
                            )}
                          </div>
                        </div>
                      </Dialog>
                    </div>
                    <div>
                      <Dialog open={isOpen}>
                        <div
                          role="alert"
                          className="rounded-xl border border-gray-100 bg-white p-4"
                        >
                          <div className="flex items-start gap-4">
                            <span className="text-red-600">
                              <FaClock size={35} color="red" />
                            </span>

                            <div className="flex-1">
                              <strong className="block font-bold">
                                {" "}
                                {errorTitle}
                              </strong>

                              <p className="mt-1 text-sm text-gray-700">
                                {message}
                              </p>
                            </div>
                          </div>

                          <div className="flex justify-center mt-4">
                            {isLoading ? (
                              <div className="flex mx-4 items-center">
                                <CircularProgress />
                              </div>
                            ) : (
                              <button
                                className="px-10 py-2 border bg-red-600 text-white rounded-xl"
                                onClick={Close}
                              >
                                Fermer
                              </button>
                            )}
                          </div>
                        </div>
                      </Dialog>
                    </div>

                    {loading ? (
                      <div className="flex mx-4 items-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="flexCenter my-4">
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Retour
                        </Button>
                        <Button
                          className="bg-blue-800"
                          variant="contained"
                          onClick={
                            activeStep === steps.length - 1
                              ? onSubmit
                              : handleNext
                          }
                        >
                          {activeStep === steps.length - 1
                            ? "Payer"
                            : "Suivant"}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ResultatsVoyage;
