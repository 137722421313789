import React from "react";
import { useParams } from "react-router-dom";



const ClientTravelDetails = ({authUser}) => {
    const id = useParams();
    
    const travel = authUser?.voyages.filter((voyage) => voyage.id = id)[0];
    const reservations = travel?.reservations 

  return (
    <div className='py-24 '>
       <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">id</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">nom du passagers </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Numéro CNI</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">N° Reservation</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">statut</th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {reservations?.sort((a, b) => b.id - a.id).map((reservation) => (
            <tr key={reservation.id}>
                <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">{reservation.id}</td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">{reservation.nom_sur_ticket}</td>
                <td className="whitespace-nowrap px-2 py-2 text-gray-700">{reservation.numero_reservation}</td>
                <td className="whitespace-nowrap px-2 py-2 text-gray-700"> 
                  <h1 className="border-1 border-blue-600 px-4 py-1 text-blue-600 cursor-pointer">
                    le statut du voyage
                  </h1> 
                  
                </td>
            </tr>
            ))}

          </tbody>
        </table>
    </div>
  )
}

export default ClientTravelDetails
