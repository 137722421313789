import React from "react";
import { CircularProgress, Link } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { FaCircleArrowLeft, FaCircleArrowRight, FaCircleXmark } from "react-icons/fa6";

const MecanicienPage = ({ profiles }) => {
  const [candidats, setCandidats] = useState([]);
  const conctat = +237698427176;
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [slideNumber, setSlideNumber] = useState(0);
  const [images, setImages] = useState([]);

  const handleOpen = (candidat, i) => {
    setSlideNumber(i);  // On utilise l'index réel
    setImages(candidat?.images || []);  // On passe simplement la liste d'URLs
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;
    const totalImages = images.length;  // Obtenir le nombre total d'images
  
    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? totalImages - 1 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === totalImages - 1 ? 0 : slideNumber + 1;
    }
  
    setSlideNumber(newSlideNumber);
  };

  useEffect(() => {
    const getCandidats = async () => {
      setCandidats(
        profiles?.filter((profile) => {
          const isValidType =
            profile.type === "technicien" || profile.type === "prestataire";
          const matchesMetier =
            profile.profil?.metier?.titre === "Mecanicien" ||
            profile.profil?.metier2?.titre === "Mecanicien" ||
            profile.profil?.metier3?.titre === "Mecanicien";

          return isValidType && matchesMetier;
        })
      );
      await new Promise((resolve) => setTimeout(resolve, 5000));
      setIsLoading(false);
    };

    getCandidats();
  }, [profiles]);
  return (
    <div>
      {open && (
        <div className="slider">
        <FaCircleXmark className="close" onClick={() => setOpen(false)} />
        <FaCircleArrowLeft className="arrow" onClick={() => handleMove("l")} />
        <div className="sliderWrapper">
          <img src={images[slideNumber]} alt="" className="sliderImg" />
        </div>
        <FaCircleArrowRight className="arrow" onClick={() => handleMove("r")} />
      </div>
      )}
      {isLoading ? (
        <div className="loader">
          <CircularProgress size={70} />
        </div>
      ) : (
        <div className="mt-8 py-16"> 
          {candidats.map((candidat, i) => (
            <div className="mx-2" key={i}>
              <div className="border-1 rounded-lg p-4 w-full max-w-xl mx-auto mb-2">
                <div className="flex justify-between items-center">
                  <div>
                    <h1 className="flex">
                      {candidat.firstName}
                      {""} {candidat.lastName}{" "}
                    </h1>
                    <h1 className="flex uppercase text-blue-500 text-sm font-bold">
                      {candidat?.profil?.metier?.titre} {candidat?.ville}{" "}
                    </h1>
                  </div>
                  <div>
                    <Link
                      className="border-2 border-blue-500 px-4 py-1 rounded-lg text-blue-600 cursor-pointer"
                      href={`https://wa.me/${conctat}?text=Bonjour Emploi Monde Je viens de vous trouver sur la plateforme les services de  ${
                        candidat.firstName + " " + candidat.lastName
                      } j'aimerais obtenir ses services Mécanicien,  Pouvez-vous me fournir plus d'informations sur ses services ?`}
                      target="_blank"
                    >
                      Contacter
                    </Link>
                  </div>
                </div>
                <div className="gap-1 rounded-lg mt-3 grid grid-cols-3 ">
                {candidat?.images?.map((image, i) => (
                  <img
                    onClick={() => handleOpen(candidat, i)} // Passer l'index de l'image
                    src={image}
                    alt=""
                    className="w-full object-cover h-full rounded-lg max-h-32 cursor-pointer"
                    key={i}
                  />
                ))}
              </div>
                {candidat?.profil?.metier2 && (
                  <div className="mt-2 flex mb-2 gap-2">
                    <p>Autres Services : </p>
                    <p className="flex uppercase text-blue-500 text-sm font-bold items-center">
                      {candidat?.profil?.metier2?.titre}{" "}
                      {candidat?.profil?.metier3?.titre}
                    </p>
                  </div>
                )}
                <div className="mt-2">
                  <p>{candidat?.profil?.biographie}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MecanicienPage;
