export const metiers = [
  {
    titre: "Ménagère",
    description: "Ménagère",
  },
  {
    titre: "Ménagère Domicilier",
    description: "Ménagère Domicilier",
  },
  {
    titre: "Nounou",
    description: "Nounou",
  },
  {
    titre: "Répétiteur à domicile",
    description: "Répétiteur à domicile",
  },
  {
    titre: "Chauffeur",
    description: "Chauffeur",
  },
  {
    titre: "Jardinier",
    description: "Jardinier",
  },
  {
    titre: "Gardien", 
    description: "Gardien"
  }
 
  
];
