import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "../utils/constant_api";
import { Alert, Stack } from "@mui/material";
import { useEffect } from "react";

const CreatePlans = () => {
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [prix, setPrix] = useState("");
  const [type, setType] = useState(""); 

  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");

  const [plans, setPlans] = useState([]);

  const DeletePlan = (plan) => {
    const token = localStorage.getItem("token");

    axios.delete(`${baseUrl}/plan/${plan.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    .then((response) => {
       if(response.status === 200 || response.status === 201 ){
        window.location.reload();
       }
    })

  };

  useEffect(() => {
    axios.get(`${baseUrl}/plans`).then((res) => {
      setPlans(res.data);
    });
  }, []);

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("prix", prix);
    formData.append("description", description);
    formData.append("duration", duration);
    formData.append("nom", nom);
    formData.append("type", type);

    try {
      await axios.post(`${baseUrl}/plans`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      window.location.href = "/admin";
    } catch (error) {
      console.log(error);
      setError(error.response.data.arrors);
      setLoading(false);
    }
  };
  return (
    <div className="py-16 flex justify-center ">
      <div className="w-full mx-auto">
        <form
          onSubmit={handleSubmit}
          className="gap-2 border rounded-xl w-full shadow-xl mt-4 max-w-xl mx-2 p-4"
        >
          {errorMessage !== "" ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{errorMessage}</Alert>
            </Stack>
          ) : (
            <span></span>
          )}

          <div>
            <h1 className="text-lg font-bold my-2">
              Création de plan d'abonnement
            </h1>
          </div>

          <div className="lg:flex gap-2">
            <div className="w-full my-2">
              <div className="flex items-start mb-1">
                <label htmlFor="titre" className="text-sm font-medium">
                  Titre de l'abonnement
                </label>
              </div>
              <input
                placeholder="Titre de l'abonnement"
                className="w-full border rounded-lg p-2"
                type="text"
                id="titre"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </div>
          </div>

          <div className="gap-2">
            <div className="w-full my-2 items-start">
              <label
                htmlFor="description"
                className="font-medium flex mb-2 text-sm"
              >
                Description de plan
              </label>
              <textarea
                placeholder="Description de plan"
                className="w-full border rounded-lg p-2"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="relative mb-2 w-full">
            <select
              className="w-full pl-12 pr-3 py-2 outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              id="villeDepart"
              name="villeDepart"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">Type D'abonnement </option>
              <option value="Inscription">Inscription </option>
                <option value="recruteur">Recruteur</option>
                <option value="technicien">Technicien</option>
                <option value="user">Employé</option>
                <option value="prestataire">Prestataire</option>
            </select>
          </div>

            <div className="w-full my-2">
              <label htmlFor="prix" className="font-medium flex mb-2 text-sm">
                Prix du plan
              </label>
              <input
                className="w-full border rounded-lg p-2"
                type="number"
                id="prix"
                value={prix}
                onChange={(e) => setPrix(e.target.value)}
              />
            </div>
          </div>

          <div className="w-full gap-2">
            <label htmlFor="duration" className="flex font-medium">
              durée du plan
            </label>
            <div className="w-full">
              <input
                placeholder="Durée du plan en en nombre de jours"
                className="w-full border rounded-lg p-2"
                type="number"
                id="duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </div>
          </div>

          {loading ? (
            <i className="fa fa-spinner fa-spin fa-2x"></i>
          ) : (
            <button
              type="submit"
              className="bg-blue-700 py-1 px-4 rounded-lg text-white mt-4"
            >
              Créer un plan
            </button>
          )}
        </form>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:items-stretch md:grid-cols-3 md:gap-8 mt-8 ">
          {plans?.map((plan, i) => (
            <div
              className="divide-y divide-gray-200 rounded-2xl border border-gray-200 shadow-sm"
              key={i}
            >
              <div className="p-6 sm:px-8">
                <h2 className="text-xl font-medium text-blue-600 border-1 p-1 border-blue-600 my-2">
                  {plan.nom}
                </h2>

                <p className="mt-4 font-medium text-gray-500">
                  {getText(plan.description)}
                </p>

                <p className="mt-2 sm:mt-4">
                  <strong className="text-3xl font-bold text-gray-900 sm:text-4xl">
                    {" "}
                    {plan.prix.split(".")[0]} FCFA
                  </strong>

                  <span className="text-sm font-medium text-gray-700">
                    /mois
                  </span>
                </p>

                <p
                  className="mt-4 block rounded border border-indigo-600 bg-red-600 px-12 py-3 text-center text-base font-medium text-white hover:bg-red-800 hover:text-indigo-600 sm:mt-6 cursor-pointer"
                  onClick={() => DeletePlan(plan)}
                >
                  supprimé ce plan
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreatePlans;
