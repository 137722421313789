import React, { useState } from "react";
import "./Auth.css";
import email_icon from "../../Assets/email.png";
import password_icon from "../../Assets/password.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import { Alert, Stack } from "@mui/material";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");

  const handleTogglePasswordVisibility = (e) => {
    e.preventDefault();
    setPasswordHidden(!isPasswordHidden);
  };

  const [isPasswordHidden, setPasswordHidden] = useState(true);

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Envoyer la requête de connexion
      const res = await axios.post(`${baseUrl}/auth/login`, data);

      if (res.status === 200 || res.status === 201) {
        localStorage.setItem("token", res.data.access_token);
        const token = localStorage.getItem("token");

        // Récupération des informations utilisateur-
        const response = await axios.get(`${baseUrl}/auth/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 || response.status === 201) {
          localStorage.setItem("userRole", response.data.type);
          // Redirection selon le rôle de l'utilisateur
          if (response.data.type === "admin") {
            window.location.href = "/admin";
          } else {
            console.log('object')
            window.location.href = "/profil-page";
          }
        }
      } else {
        setError(
          res.response?.data?.errors || "Une erreur inattendue s'est produite."
        );
      }
    } catch (error) {
      console.log(error);

      if (error.response) {
        // Traiter les erreurs spécifiques de l'API
        const statusCode = error.response.status;
        if (statusCode === 403) {
          setError(error.response.data.errors);
          localStorage.setItem("phoneNumber", error.response.data.phoneNumber);
          localStorage.setItem("password", data.password);
          navigate("/otp-confirmation");
        } else if (statusCode === 400) {
          setError(
            "Requête invalide. Veuillez vérifier les informations saisies."
          );
        } else if (statusCode === 401) {
          setError("Identifiants incorrects.");
        } else {
          setError(
            `Erreur inattendue: ${
              error.response?.data?.message || "Veuillez réessayer."
            }`
          );
        }
      } else {
        // Traiter les erreurs sans réponse (ex: pas de connexion réseau)
        setError("Erreur réseau. Veuillez vérifier votre connexion.");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="mt-24">
      <div className="form-container">
        <div className="form-header">
          <div className="form-text">Connexion</div>
          <div className="underline "></div>
        </div>
        {errorMessage !== "" ? (
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">{errorMessage}</Alert>
          </Stack>
        ) : (
          <span></span>
        )}
        <form>
          <div className="inputs">
            <div className="form-input">
              <img src={email_icon} alt="" />
              <input
                type="email"
                placeholder="Email ou Numéro de téléphone"
                name="email"
                value={data.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-input">
              <img src={password_icon} alt="" />
              <input
                type={isPasswordHidden ? "password" : "text"}
                placeholder="Mot de passe"
                name="password"
                value={data.password}
                onChange={handleChange}
                required
              />
              <span
                className="mx-4"
                onClick={(e) => handleTogglePasswordVisibility(e)}
              >
                {isPasswordHidden ? (
                  <FaEye color="blue" size={20} />
                ) : (
                  <FaEyeSlash color="blue" size={20} />
                )}
              </span>
            </div>
          </div>

          <div className="submit-container">
            {loading ? (
              <i className="fa fa-spinner fa-spin fa-2x"></i>
            ) : (
              <div className="inline-block w-full">
                <button className="submit mx-auto" onClick={(e) => onSubmit(e)}>
                  Se connecter
                </button>
                  <Link to="/reset-password" className="text-blue-600">Mot de passe oublié ? </Link>
              </div>
            )}
          </div>
        </form>
        <div className="compte">
          Pas de compte ?{" "}
          <span>
            <Link to="/offre">cliquez ici</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Auth;
