import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../utils/constant_api";

const VerifyOtp = () => {
  const [errorMessage, setError] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const phoneNumber = localStorage.getItem("phoneNumber");
  const password = localStorage.getItem("password");
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!phoneNumber || !password) {
  //     navigate("/"); // Redirige vers l'accueil si des données manquent
  //   }
  // }, [navigate, phoneNumber, password]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otp) {
      setError("Veuillez entrer le code OTP.");
      return;
    }

    setLoading(true);
    axios
      .post(`${baseUrl}/verify-otp`, { phoneNumber, otp })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const formData = new FormData();
          formData.append("email", phoneNumber);
          formData.append("password", password);

          
          axios.post(`${baseUrl}/auth/login`, formData)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              localStorage.setItem("token", res.data.access_token);
              const token = localStorage.getItem("token");
              return axios.get(`${baseUrl}/auth/me`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            } else {
              setError("Erreur lors de la connexion.");
              setLoading(false);
            }
          })
          .then((response) => {
            if (response.data.type === "admin") {
              navigate("/admin");
            } else {
              navigate("/profil-page");
              window.location.reload();
            }
          })
        } else {
          setError(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(
          "Erreur: " + (error.response?.data?.errors || "Problème de connexion")
        );
        setLoading(false);
      });
  };

  const resetOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${baseUrl}/send-otp`, { phoneNumber })
      .then((res) => {
        setLoading(false);
        if (res.status === 200 || res.status === 201) {
          setSuccess(true);
          setError(res.data.message);
        } else {
          setError(res.data.message);
        }
      })
      .catch((error) => {
        setError(
          error.response?.data?.message || "Erreur lors de l'envoi du OTP."
        );
        setLoading(false);
      });
  };

  return (
    <div className="login paddinds container">
      <div className="paddings w-full max-w-xl shadow-lg border rounded-lg my-10 gap-4 mx-2">
        <div className="mx-auto rounded-full border shadow-lg w-32 h-32 items-center flex">
          <img
            src="images/emploi-monde.png"
            alt="logo"
            className="object-cover w-full h-full"
          />
        </div>

        <form
          action="flex justify-center w-full"
          className=""
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="w-full p-4">
            <h3 className="font-semibold text-xl text-center ">
              Vérification du numéro de téléphone
            </h3>
            <h3 className="text-center text-gray-600 font-medium mb-2">
              Nous vous avons envoyer un message au numéro {phoneNumber}
            </h3>
            {errorMessage !== "" && !success ? (
              <div className="btn btn-outline-danger w-53 my-1 items-center justify-center">
                <i className="fa fa-warning"></i> <p>{errorMessage}</p>
              </div>
            ) : (
              <span></span>
            )}
            {errorMessage !== "" && success ? (
              <div className="btn btn-outline-success w-53 my-1 items-center">
                <i className="fa fa-check-circle fa-2x items-center"></i> 
                <p>{errorMessage}</p>
              </div>
            ) : (
              <span></span>
            )}
            <div className="w-full mt-3 mb-2">
              <input
                type="text"
                className="w-full p-2 border rounded-md bg-gray-100"
                name="otp"
                value={otp}
                placeholder="Entrez le code de confirmation"
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>{" "}
            {loading ? (
              <i className="fa fa-spinner fa-spin fa-2x"></i>
            ) : (
              <button className="w-full bg-blue-700 rounded-md text-white p-2">
                Confirmer
              </button>
            )}
            <div className="mt-4 w-full flex justify-center">
              <span
                className="text-center text-white cursor-pointer border-1 py-1.5 px-3 rounded-md bg-blue-600 w-full"
                onClick={(e) => resetOtp(e)}
              >
                Envoyer a nouveau
              </span>
            </div>
          </div>
        </form>

        
      </div>
    </div>
  );
};

export default VerifyOtp;
