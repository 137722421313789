import { Link } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const MaisonFilter = ({ profiles }) => {
  const [candidats, setCandidats] = useState([]);
  const metierName = useParams();

  const conctat = +237698427176;

  const getCandidats = () => {
    if (metierName !== undefined) {
      setCandidats(
        profiles.filter(
          (profile) =>
            ((profile.type === "technicien" || profile.type === "prestataire" || profile.type === "employe" ) &&
            profile?.profil?.metier?.titre === metierName.metierName) ||
            profile?.profil?.metier2?.titre === metierName.metierName ||
            profile?.profil?.metier3?.titre === metierName.metierName
        )
      );
    }
  };

  useEffect(() => {
    getCandidats();
  }, []);

  return (
    <div>
      <div className="pt-20">
        <div>
          {candidats.map((candidat, i) => (
            <div className="mx-2" key={i}>
              <div className="border-1 rounded-lg p-4 w-full max-w-xl mx-auto mb-2">
                <div className="flex justify-between items-center">
                  <div>
                    <h1 className="flex">
                      {candidat.firstName}
                      {""} {candidat.lastName}{" "}
                    </h1>
                    <h1 className="flex uppercase text-blue-500 text-sm font-bold">
                      {candidat?.profil?.metier?.titre} {candidat?.ville}{" "}
                    </h1>
                  </div>
                  <div>
                    <Link
                      className="border-2 border-blue-500 px-4 py-1 rounded-lg text-blue-600 cursor-pointer"
                      href={`https://wa.me/${conctat}?text=Bonjour emploi monde Besoin de votre employé   ${  candidat.firstName + " " + candidat.lastName } {" "} ${ metierName.metierName },  `}
                      target="_blank"
                    >
                      Contacter
                    </Link>
                  </div>
                </div>
                <div className="gap-1 rounded-lg mt-3 grid grid-cols-3 ">
                  {candidat?.images?.map((image, i) => (
                    <img
                      src={image}
                      alt=""
                      className="w-full object-cover h-full rounded-lg max-h-32"
                      key={i}
                    />
                  ))}
                </div>
                <div className="mt-2 flex mb-2 gap-2">
                  <p>Autres Services : </p>
                  <p className="flex uppercase text-blue-500 text-sm font-bold items-center">
                    {candidat?.profil?.metier2?.titre}{" "}
                    {candidat?.profil?.metier3?.titre}
                  </p>
                </div>
                <div className="mt-2">
                  <p>{candidat?.profil?.biographie}</p>
                </div>
              </div>
            </div>
          ))}
           {candidats.length === 0 && (
          <div>
            <div>
              <h1 className="font-bold text-2xl text-slate-500">
                Désolé...aucun résultat
              </h1>
            </div>
            <div>
              <h1 className="font-medium text-lg text-slate-500">
                {" "}
                Nous n'avons pas de ${ metierName.metierName} disponible pour l'instant. Merci de revenir plus tard.
              </h1>
            </div>
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

export default MaisonFilter;
