import React from "react";
import profil from "../../Assets/user.png";
import { FaCheck } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { Link } from "react-router-dom";

const ProfilCard = ({ authUser }) => {
  return (
    <div className="relative block overflow-hidden rounded-lg border w-full border-gray-100 p-4 sm:p-6 lg:p-8 max-w-lg mx-2 mt-2">
      <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>

      <div className="flex border-black border-b-2 justify-between mb-2 p-2 items-center">
        <span className="font-bold"> {authUser?.firstName} {authUser?.lastName} </span>
        <span className="border-2 rounded-full p-2 text-xs w-10 h-10 flex items-center justify-center border-green-600 font-medium">
          100%
        </span>
      </div>
      <div className="my-4">
          <div className="w-36 h-36 mx-auto">
            <img
              className="rounded-full object-cover w-full h-full shadow-lg"
              alt=""
              src={authUser.images?.length > 0 ? authUser?.images[0] : profil}
            />
          </div>
        </div>
      <div className="flex justify-between gap-4">
        <div className="w-full">
          <div className="flex  items-center">
            <div className="w-1/3 flex">
              <span className="text-sm font-medium">Langue : </span>
            </div>
            <div className="w-2/3 flex">
              <span className="text-sm font-bold mx-2">
                {authUser?.profil?.langueprincipale}
              </span>
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-1/3 flex">
              <span className="text-sm font-medium">Diplome : </span>
            </div>
            <div className="w-2/3 flex">
              <span className="text-sm font-bold mx-2">
                {authUser?.profil?.diplome}
              </span>
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-1/3 flex">
              <span className="text-sm font-medium">Metier : </span>
            </div>
            <div className="w-2/3 flex">
              <span className="text-sm font-bold mx-2">
                {authUser?.profil?.metier?.titre}
              </span>
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-1/3 flex">
              <span className="text-sm font-medium">Residence : </span>
            </div>
            <div className="w-2/3 flex">
              <span className="text-sm font-bold mx-2">
                {authUser?.profil?.ville}, {authUser?.profil?.pays}
              </span>
            </div>
          </div>
          <div className="flex items-center ">
            <div className="w-1/3 flex items-start justify-start">
              <span className="text-sm font-medium">Naisssance : </span>
            </div>
            <div className="w-2/3 flex">
              <span className="text-sm font-bold mx-2">
                {authUser?.profil?.datenais}
              </span>
            </div>
          </div>

          <div className="flex items-center ">
            <div className="w-1/3 flex items-start justify-start">
              <span className="text-sm font-medium">Statut : </span>
            </div>
            <div className="w-2/3 flex">
              <span className="text-sm font-bold mx-2 mt-1">
                {authUser.is_premium ? (
                   <div className="flex items-center gap-2">
                   <FaCheck size="20" color="green" />{" "}
                   <span>Actif</span>
                 </div>
                ) : (
                  <div className="flex items-center gap-2">
                    <RiCloseCircleFill size="20" color="red" />{" "}
                    <span>inactif</span>
                  </div>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="text-pretty text-sm text-gray-600 text-justify">
          {authUser?.profil?.biographie}
        </p>
      </div>

      <div className="mt-6 flex gap-4 sm:gap-6 items-center justify-between">
        <div>
          <a className="bg-blue-700 px-4 py-2 text-white rounded-lg"
          href="/update_profil"
          >
          Modifier
          </a>
        </div>
        <div className="flex flex-col-reverse">
          <dt className="text-sm font-medium text-gray-600">
            {authUser?.profil?.updated_at.split("T")[0]}
          </dt>
          <dd className="text-xs text-gray-500">Denière mise à jour</dd>
        </div>
      </div>
    </div>
  );
};

export default ProfilCard;
