import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Grid,
  InputAdornment,
  IconButton,
  Alert,
  Stack,
  Dialog,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { baseUrl } from "../utils/constant_api";
import axios from "axios";
import { useRef } from "react";
import { FaCheck, FaClock } from "react-icons/fa";

const theme = createTheme();

const AgencyRegister = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setError] = useState("");
  const [ville, setVille] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [lastName, setLastName] = useState("");
  const pays = "Cameroun";

  const [num_finance_mtn, setnum_finance_mtn] = useState("");
  const [num_finance_orange, setnum_finance_orange] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const [image, setImage] = useState([]);

  const [images, setImages] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const fileInputRef = useRef(null);

  const Close = () => {
    setIsOpen(false);
  };

  const onFileSelect = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    const files = Array.from(event.target.files);
    setMyFiles(files);

    if (files.length === 0) return;

    const imageFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
      imageFiles.push(files[i]);
    }
    setMyFiles(imageFiles);
    console.log(images);
  };
  const validateForm = () => {
    return (
      lastName.length > 0 &&
      firstName.length > 0 &&
      ville.length > 0 &&
      email.length > 0 &&
      password.length > 0
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (validateForm()) {
      const formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("pays", pays);
      formData.append("ville", ville);
      formData.append("phoneNumber", phoneNumber);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("num_finance_mtn", num_finance_mtn);
      formData.append("num_finance_orange", num_finance_orange);
      formData.append("isAgence", true);
      myFiles.forEach((file) => formData.append("images[]", file));

      axios
        .post(`${baseUrl}/employe/register`, formData)
        .then((res) => {
          setLoading(false);
          if (res.status === 201) {
            setSuccess(true);
            window.location.href = "/login";
          } else {
            setError(res.data.message);
            setLoading(false);
            setMessage(res.data.message);
            setIsOpen(true);
          }
        })
        .catch((error) => {
          console.log(error)
          setError(error.response.data.errors);
          setMessage(error.response.data.errors);
          setLoading(false);
          setIsOpen(true);
        });
    } else {
      setIsOpen(true);
      setMessage("veuillez Remplir tous les champs");
      setLoading(false);
    }
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 6,
          }}
        >
          <h1 className="font-medium">Creation de l'espace agence de voyage</h1>

          {errorMessage !== "" ? (
            <Stack sx={{ width: "100%" }} spacing={1}>
              <Alert severity="error">{errorMessage}</Alert>
            </Stack>
          ) : (
            <span></span>
          )}

          <div>
            <Dialog open={isOpen}>
              <div
                role="alert"
                className="rounded-xl border border-gray-100 bg-white p-4"
              >
                <div className="flex items-start gap-4">
                  {success ? (
                    <span className="text-green-600">
                      <FaCheck size={35} color="green" />
                    </span>
                  ) : (
                    <span className="text-red-600">
                      <FaClock size={35} color="red" />
                    </span>
                  )}

                  <div className="flex-1">
                    <strong className="block font-bold">
                      {success ? "success" : "Alert"}{" "}
                    </strong>

                    <p className="mt-1 text-sm text-gray-700">{message}</p>
                  </div>

                </div>
                  <div className="flex justify-center mt-4">
                    <button
                      className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                      onClick={Close}
                    >
                      {success ? "Se connecter" : "fermer"}
                    </button>
                  </div>
              </div>
            </Dialog>
          </div>

          <form onSubmit={handleSubmit} sx={{ mt: 3, mb: 1 }}>
            <Grid container>
              <div className=" border-2 border-blue-600 border-dashed p-2 w-full mt-4">
                <div className="new-bottomLeft">
                  <img src={image} alt="" />
                </div>
                <div className="newFormInput justify-center flex">
                  <label
                    htmlFor="file"
                    className="rounded-xl border-2 border-blue-600  px-4 py-1 my-2"
                  >
                    Ajoutez le logo de votre entreprise{" "}
                    <DriveFolderUploadOutlinedIcon className="icon" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    ref={fileInputRef}
                    onChange={onFileSelect}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              <div className="w-full mt-4">
                <select
                  name="HeadlineAct"
                  id="HeadlineAct"
                  className=" w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3 mb-2"
                  onChange={(e) => setVille(e.target.value)}
                  value={ville}
                >
                  <option value="">Ville </option>
                  <option value="Bafoussam">Bafoussam</option>
                  <option value="Bamenda">Bamenda</option>
                  <option value="Douala">Douala</option>
                  <option value="Edéa">Edéa</option>
                  <option value="Yaoundé">Yaoundé</option>
                </select>
              </div>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Nom de l'agence"
                  required
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <label htmlFor="" className="flex mb-0 font-medium ml-1">
                  Agence de:{" "}
                </label>
                <TextField
                  className="mt-0"
                  placeholder="Ex: Mvan, Akwa(douche)"
                  required
                  name="fisrtName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <div className="flex w-full gap-2">
                <TextField
                  label="Numéro Contact"
                  type="number"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Grid>
            <div className="w-full gap-2">
              <TextField
                required
                label="Numéro Finance MTN"
                type="number"
                name="phoneNumber"
                value={num_finance_mtn}
                onChange={(e) => setnum_finance_mtn(e.target.value)}
                fullWidth
                margin="normal"
              />

              <TextField
                required
                label="Numéro Finance ORANGE"
                type="number"
                name="phoneNumber"
                value={num_finance_orange}
                onChange={(e) => setnum_finance_orange(e.target.value)}
                fullWidth
                margin="normal"
              />
            </div>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                required
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mot de passe"
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {loading ? (
              <i className="fa fa-spinner mt-2 text-danger fa-spin fa-2x"></i>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                S'inscrire
              </Button>
            )}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                  Deja un compte ? Connectez vous.
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AgencyRegister;
