import React, { useEffect, useState } from "react";
import ProfilCard from "../ClientDashboard/ProfilCard";
import { baseUrl } from "../../utils/constant_api";
import axios from "axios";
import Auth from "../../pages/Login/Auth";
import { Link } from "react-router-dom";
import profil from "../../Assets/user.png";
import { FaCheck } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { CircularProgress } from "@mui/material";

const ProfilPage = ({ authUser }) => {
  const [isLoading, setIsLoading] = useState(true);

  const logout = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    await axios
      .post(
        `${baseUrl}/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.clear();
          // setIsLoading(false)
          window.location.href = "/";
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error)
      })
  };

  useEffect(() => {
    const isConnected = async () => {
      if (!authUser) {
        console.log('utilisateur connecté')
      } else {
        console.log('aucun utilisateur connecter')
      }
      await new Promise((resolve) => setTimeout(resolve, 4000));
      setIsLoading(false)
    };
    isConnected()
  }, [authUser])


  return (
    <div>
    {isLoading ? (
      <div className="loader">
        <CircularProgress size={70} />
      </div>
    ) : (
    <div className="py-20">
      {authUser && (
        <div>
          <div className="mx-auto md:flex justify-center w-full">
            {authUser?.type === "employe" && <ProfilCard authUser={authUser} />}
          </div>

          <div className="mx-auto md:flex justify-center">
            {(authUser?.type === "technicien" ||
              authUser?.type === "prestataire") && (
              <div className="border-1 rounded-lg p-4 w-full max-w-lg mx-2">
                <div className="flex justify-between items-center">
                  <div>
                    <h1 className="flex">
                      {authUser.firstName}
                      {""} {authUser.lastName}{" "}
                    </h1>
                    <h1 className="flex uppercase text-blue-500 text-sm font-bold">
                      {authUser?.type} {authUser?.metier?.titre}{" "}
                      {authUser?.ville}{" "}
                    </h1>
                  </div>
                  {/* <div>
                    <h1 className="border-2 border-blue-500 px-4 py-1 rounded-lg text-blue-600 ">
                      Modifier
                    </h1>
                  </div> */}
                </div>
                <div className="flex items-center my-2">
                  <div className="w-1/3 flex items-start justify-start">
                    <span className="text-sm font-medium">Statut : </span>
                  </div>
                  <div className="w-2/3 flex">
                    <span className="text-sm font-bold mx-2 mt-1">
                      {authUser.is_premium ? (
                        <div className="flex items-center gap-2">
                          <FaCheck size="20" color="green" /> <span>Actif</span>
                        </div>
                      ) : (
                        <div className="flex items-center gap-2">
                          <RiCloseCircleFill size="20" color="red" />{" "}
                          <span>inactif</span>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                <div className="gap-1 rounded-lg mt-3 grid grid-cols-3 ">
                  {authUser?.images?.map((image, i) => (
                    <img
                      src={image}
                      alt=""
                      className="w-full object-cover h-full rounded-lg"
                      key={i}
                    />
                  ))}
                </div>

                <div className="flex items-center">
            <div className="w-1/3 flex">
              <span className="text-sm font-medium">Residence : </span>
            </div>
            <div className="w-2/3 flex">
              <span className="text-sm font-bold mx-2">
                {authUser?.profil?.ville}, {authUser?.profil?.pays}
              </span>
            </div>
          </div>

          <div className="flex items-center ">
            <div className="w-1/3 flex items-start justify-start">
              <span className="text-sm font-medium">Naisssance : </span>
            </div>
            <div className="w-2/3 flex">
              <span className="text-sm font-bold mx-2">
                {authUser?.profil?.datenais}
              </span>
            </div>
          </div>

          
                <div className="mt-2">
                  <p>{authUser?.profil?.biographie}</p>
                </div>
              </div>
            )}
          </div>

          <div className="mx-auto md:flex justify-center">
            {authUser?.type === "recruteur" && (
              <div className="relative block overflow-hidden rounded-lg border w-full border-gray-100 p-4 sm:p-6 lg:p-8 max-w-lg mt-2">
                <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>

                <div className="flex border-black border-b-2 justify-between mb-2 p-2 items-center">
                  <span className="font-bold uppercase">
                    {authUser?.firstName} : {authUser?.recruteur?.entreprise}{" "}
                  </span>
                  <span className="border-2 rounded-full p-2 text-xs w-10 h-10 flex items-center justify-center border-green-600 font-medium">
                    100%
                  </span>
                </div>
                <div className=" justify-between gap-4 pb-4">
                  <div className="p-4 w-full">
                    <img
                      className="rounded-full object-cover w-28 max-h-32 mx-auto"
                      alt=""
                      src={
                        authUser.images?.length > 0
                          ? authUser?.images[0]
                          : profil
                      }
                    />
                  </div>
                  <div className="w-full">
                    <div className="flex items-center">
                      <div className="w-1/3 flex">
                        <span className="text-sm font-medium">
                          Entreprise :{" "}
                        </span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm uppercase font-bold mx-2">
                          {authUser?.recruteur?.entreprise}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <div className="w-1/3 flex">
                        <span className="text-sm font-medium">Email : </span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm font-bold mx-2">
                          {authUser?.recruteur?.email}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <div className="w-1/3 flex">
                        <span className="text-sm font-medium">Ville : </span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm font-bold mx-2">
                          {authUser?.ville}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center ">
                      <div className="w-1/3 flex items-start justify-start">
                        <span className="text-sm font-medium">
                          Télephone :{" "}
                        </span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm font-bold mx-2">
                          {authUser?.recruteur?.phone}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center ">
                      <div className="w-1/3 flex items-start justify-start">
                        <span className="text-sm font-medium">Statut : </span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm font-bold mx-2 mt-1">
                          {authUser.is_premium ? (
                            <div className="flex items-center gap-2">
                              <FaCheck size="20" color="green" />{" "}
                              <span>Actif</span>
                            </div>
                          ) : (
                            <div className="flex items-center gap-2">
                              <RiCloseCircleFill size="20" color="red" />{" "}
                              <span>inactif</span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mmb-2 pt-2 flex justify-center items-center">
                  {authUser.is_premium === true ? (
                    <Link
                      className="bg-blue-600 text-white px-4 py-1 rounded-sm"
                      to="/employers"
                    >
                      Trouver des employers
                    </Link>
                  ) : (
                    <div>
                      <h1 className="bg-red-100 text-red-800 p-2 rounded-lg">
                        Veuillez payer votre abonnement annuel pour avoir accès
                        aux employers
                      </h1>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="mx-auto md:flex justify-center">
            {authUser?.type === "agence" && (
              <div className="relative block overflow-hidden rounded-lg border border-gray-100 p-4 sm:p-6 lg:p-8 max-w-lg mt-2 md:mx-auto w-full">
                <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>

                <div className="flex border-black border-b-2 justify-between mb-2 p-2 items-center">
                  <span className="font-bold">
                    {authUser?.lastName} {":"} {authUser?.firstName}
                  </span>
                  <span className="border-2 rounded-full p-2 text-xs w-10 h-10 flex items-center justify-center border-green-600 font-medium">
                    100%
                  </span>
                </div>
                <div className="">
                  <a
                    className="flex border-1 text-blue-600 items-center justify-center border-blue-600 rounded"
                    href="/update_profil"
                  >
                    Mettre à jour
                  </a>
                </div>
                <div className="justify-between gap-4 pt-4 mb-4">
                  <div className="w-32 h-32 mx-auto mb-8">
                    <img
                      className="rounded-full object-cover h-full"
                      alt=""
                      src={
                        authUser.images?.length > 0
                          ? authUser?.images[0]
                          : profil
                      }
                    />
                  </div>
                  <div className="px-8">
                    <div className="flex items-center">
                      <div className="w-1/3 flex">
                        <span className="text-sm font-medium">
                          Entreprise :{" "}
                        </span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm uppercase font-bold mx-2">
                          {authUser?.lastName}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <div className="w-1/3 flex">
                        <span className="text-sm font-medium">Agence: </span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm font-bold mx-2">
                          {authUser?.firstName}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <div className="w-1/3 flex">
                        <span className="text-sm font-medium">MTM MOMO:</span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm font-bold mx-2 ">
                          {authUser?.profil.num_finance_mtn}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="w-1/3 flex">
                        <span className="text-sm font-medium">Orange MO:</span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm font-bold mx-2 ">
                          {authUser?.profil.num_finance_orange}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center ">
                      <div className="w-1/3 flex items-start justify-start">
                        <span className="text-sm font-medium">
                          Télephone :{" "}
                        </span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm font-bold mx-2 w-full text-left">
                          {authUser?.phoneNumber}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-2 pt-4 flex justify-center items-center">
                  <Link
                    className="bg-blue-600 text-white px-4 py-1 rounded-sm"
                    to="/agence-travel"
                  >
                    Gestion des voyages
                  </Link>
                </div>
              </div>
            )}
          </div>

          {authUser.is_premium === false && authUser.type !== "agence" && (
            <div className="mt-4 max-w-lg w-full border-1 rounded-lg p-2 mx-auto">
              <h1 className="text-neutral-500 font-medium">
                {!authUser.isInscrit
                  ? `Votre compte est inactif veuillez l'activer pour beneficier de
                tout les fonctionnalités de l'application.`
                  : `Veuillez renouveler votre Abonnement a fin d'activer votre compte`}
              </h1>
              <div className="flex justify-center items-center">
                <Link
                  to="/mon-plan-premium"
                  className="mt-2 rounded-lg py-2 w-full bg-blue-600 items-center text-center text-white"
                >
                  {authUser?.type === "recruteur"
                    ? "Payer mon abonnement"
                    : "Activer mon compte"}
                </Link>
              </div>
            </div>
          )}

          <div className="w-full max-w-lg mx-auto mt-2">
            <button
              onClick={logout}
              className="bg-red-600 py-2 text-white border rounded-xl px-8 w-full"
            >
              Deconnexion
            </button>
          </div>
        </div>
      )}

      {!authUser && <Auth />}
    </div>
      )}
    </div>
  );
};

export default ProfilPage;
