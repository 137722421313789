import React, { useState } from "react";
import axios from "axios";
import {
  Alert,
  Stack,
} from "@mui/material";
import { baseUrl } from "../../utils/constant_api";
import { Destinations } from "../../components/Travel/Voyage";
import { FaLocationArrow } from "react-icons/fa6";
const CreateVoyageForm = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");
  const [prix, setPrix] = useState("");
  // const [ville_depart, setville_depart] = useState("");
  const [ville_arrive, setville_arrive] = useState("");
  const [nombre_place, setnombre_place] = useState("");
  const [date_de_depart, setdate_de_depart] = useState();
  const [heure_depart, setheure_depart] = useState("");
  // const [heure_arrive, setheure_arrive] = useState("");
  const frais_service = 3;
  const [type_voyage, setTypeVoyage] = useState("");
  const [arret, setArret] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem("token");

    const formData = new FormData();
    // formData.append("ville_depart", ville_depart);
    // formData.append("logo_agence", file);
    formData.append("prix", prix);
    formData.append("ville_arrive", ville_arrive);
    formData.append("heure_depart", heure_depart);
    // formData.append("heure_arrive", heure_arrive);
    formData.append("nombre_place", nombre_place);
    formData.append("arret", arret);
    formData.append("date_de_depart", date_de_depart);
    formData.append("frais_service", frais_service);
    formData.append("type_voyage", type_voyage);

    axios
      .post(`${baseUrl}/voyage/new`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          setLoading(false);
          window.location.href = "/profil-page";
        } 
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setError(error.response.data.errors);
      });
  };

  return (
    <div className="py-16 flex justify-center ">
      <form
        onSubmit={handleSubmit}
        className="gap-2 border rounded-xl w-full shadow-xl mt-4 max-w-xl mx-2 p-4"
      >
        {errorMessage !== "" ? (
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">{errorMessage}</Alert>
          </Stack>
        ) : (
          <span></span>
        )}

        <div>
          <h1 className="text-lg font-bold my-2">Création d'un voyage</h1>
        </div>

        {/* <div className="my-2">
          <div className="flex justify-center items-center">
            <img
              src={logo_agence}
              alt=""
              className="h-28 w-28 border-1 mb-2 rounded-full object-cover self-center border-blue-700"
            />
          </div>
          <div className="items-center cursor-pointer">
            <label htmlFor="file" className="border-2 px-4 rounded-lg border-blue-700 text-blue-700">
              Ajoutez le logo de l'entreprise
              <DriveFolderUploadOutlinedIcon className="icon" />
            </label>
            <input
              type="file"
              id="file"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div> */}
        <div className="lg:flex gap-2 mt-4">
          <div className="w-full my-2">
            <div className="flex items-start mb-1">
              <label htmlFor="prix" className="text-sm font-medium">
                Prix du ticket
              </label>
            </div>
            <input
              placeholder="prix du ticket"
              className="w-full border rounded-lg p-2"
              type="number"
              id="prix"
              name="prix"
              value={prix}
              onChange={(e) => setPrix(e.target.value)}
            />
          </div>
        </div>

        <div className=" gap-2">
          {/* <div className="relative max-w-xs mb-2 w-full">
            <TiLocation className='"w-6 h-6 text-gray-400 absolute left-3 inset-y-0 my-auto' />
            <select
              className="w-full pl-12 pr-3 py-2 outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              id="villeDepart"
              name="villeDepart"
              value={ville_depart}
              onChange={(e) => setville_depart(e.target.value)}
            >
              <option value="">Ville de depart</option>
              {Destinations.map((destination) => (
                <option key={destination.id} value={destination.name}>
                  {destination.name}
                </option>
              ))}
            </select>
          </div> */}

          <div className="relative mb-2 w-full">
            <FaLocationArrow className='"w-6 h-6 text-gray-400 absolute left-3 inset-y-0 my-auto' />
            <select
              className="w-full pl-12 pr-3 py-2 outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              id="villeArrivee"
              name="villeArrivee"
              value={ville_arrive}
              onChange={(e) => setville_arrive(e.target.value)}
            >
              <option value="" className="text-gray-500">
                ville d'arriver
              </option>
              {Destinations.map((destination) => (
                <option key={destination.id} value={destination.name}>
                  {destination.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="w-full my-2 items-start">
            <label
              htmlFor="heure_depart"
              className="font-medium flex mb-2 text-sm"
            >
              Heure de départ
            </label>
            <input
              placeholder="heure de départ"
              className="w-full border rounded-lg p-2"
              type="time"
              id="heure_depart"
              name="heure_depart"
              value={heure_depart}
              onChange={(e) => setheure_depart(e.target.value)}
            />
          </div>

          {/* <div className="w-full my-2">
            <label
              htmlFor="heure_arrive"
              className="font-medium flex mb-2 text-sm"
            >
              Heure d'arrivée
            </label>
            <input
              className="w-full border rounded-lg p-2"
              type="time"
              id="heure_arrive"
              name="heure_arrive"
              value={heure_arrive}
              onChange={(e) => setheure_arrive(e.target.value)}
            />
          </div> */}
        </div>

        <div className="w-full flex gap-2">
          {/* <label htmlFor="nombre_place">Nombre de places</label> */}
          <div className="w-full">
            <input
              placeholder="Nombre de place pour le voyage"
              className="w-full border rounded-lg p-2"
              type="number"
              id="nombre_place"
              name="nombre_place"
              value={nombre_place}
              onChange={(e) => setnombre_place(e.target.value)}
            />
          </div>
          <div className="mb-2 w-full">
            <select
              className="w-full pl-12 pr-3 py-2 outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              id="villeDepart"
              name="villeDepart"
              value={type_voyage}
              onChange={(e) => setTypeVoyage(e.target.value)}
            >
              <option value="">Type de voyage</option>
              <option value="VIP">VIP</option>
              <option value="Classic">Classic</option>
            </select>
          </div>
        </div>
        <div className="w-full my-2">
          <div className="flex items-start mb-1">
            <label htmlFor="prix" className="text-sm font-medium">
              Point d'arret
            </label>
          </div>
          <input
            placeholder="Indiquez sont les point d'arret pour ce trajet"
            className="w-full border rounded-lg p-2"
            type="text"
            value={arret}
            onChange={(e) => setArret(e.target.value)}
          />
        </div>
        <div className="w-full my-2">
          {/* <label htmlFor="date_de_depart">Date de départ</label> */}
          <input
            className="w-full border rounded-lg p-2"
            placeholder="Date de depart"
            type="date"
            id="date_de_depart"
            name="date_de_depart"
            onChange={(e) => setdate_de_depart(e.target.value)}
          />
        </div>

        {loading ? (
          <i className="fa fa-spinner fa-spin fa-2x"></i>
        ) : (
          <button
            type="submit"
            className="bg-blue-700 py-1 px-4 rounded-lg text-white"
          >
            Créer un voyage
          </button>
        )}
      </form>
    </div>
  );
};

export default CreateVoyageForm;
